import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import React from 'react'
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';

const PrivacyPage = () => {
    return ( 
        <Layout>
            <Seo title="FAQs" />
            <Container>
                
                    <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label:'Green Hydrogen'}]}/>
                    
                    <h1 className='h4'>Green Hydrogen</h1>
            </Container>
        </Layout>
    );
}
 
export default PrivacyPage;